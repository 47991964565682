<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span>发放查询</span>
      </template>
    </HeaderBox>

    <div class="dlex">
      <div class="dlexitem">
        <span>姓名：</span>
        <a-input class="nput" placeholder="请输入姓名" v-model="proJect.name" />
      </div>
      <div class="dlexitem">
        <span>手机号：</span>
        <a-input
          class="nput"
          placeholder="请输入手机号"
          v-model="proJect.mobile"
        />
      </div>
      <div class="dlexitem">
        <span>关联课程：</span>
        <a-select
          class="nput"
          placeholder="请选择关联课程"
          v-model="proJect.courseId"
        >
          <a-select-option
            v-for="(item, index) in courseList"
            :key="index"
            :value="item.courseId"
          >
            {{ item.courseName }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="dlex">
      <div class="dlexitem">
        <span>分数区间</span>
        <a-input-number placeholder="最低" id="inputNumber" v-model="proJect.min" :min="1" />
        ~
        <a-input-number placeholder="最高" id="inputNumber" v-model="proJect.max" :min="1" />
      </div>
      <div class="dlexitem">
        <span>作业名称：</span>
        <a-input
          class="nput"
          placeholder="请输入作业名称"
          v-model="proJect.homeworkName"
        />
      </div>
      <div class="dlexitem">
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.workUnitId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="historyStatus" slot-scope="item, row">
          <div style="text-align: center">
            {{ statuslist[row.status] }}
          </div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="gohomeWorkDetail(item.answerId, 2)">查看作业</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "关联课程",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "作业名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "提交时间",
    align: "center",
    dataIndex: "submitTime",
  },
  {
    title: "提交次数",
    align: "center",
    dataIndex: "secondeflag",
  },
  {
    title: "批阅状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "historyStatus" },
  },
  {
    title: "批阅人",
    align: "center",
    dataIndex: "teacherName",
  },
  {
    title: "分数",
    align: "center",
    dataIndex: "score",
  },

  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      selectedKeys: "", //当前选择的
      loading: false, // 列表加载
      tableData: [], // 列表数据
      courseList: [], // 关联课程列表
      total: 0, // 总数
      // 作业状态
      statuslist: [
        "未提交",
        "暂存",
        "测试",
        "已提交",
        "测试",
        "已驳回",
        "已驳回",
        "已审批",
        "成绩发布",
      ],
      // 数据
      proJect: {
        name: null, // 用户名称
        mobile: null,
        pageNum: 1,
        pageSize: 10,
        courseId: null,
        userName: null,
        homeworkName: null, // 作业名称
        max: null,
        min: null,
      },
      keyWord: "",
      columns,
      pageNum: 1, //页码
      pageSize: 10, // 页数
      listtype: null, // 判断是什么情况1看列表,2阅卷

      courseName: null, // 作业名称
    };
  },
  // 事件处理器
  methods: {
    // 学员配置
    onDetail(answerId,type,id){
      this.$router.push({ path: "/admin/Subjective/answerDetail", query: { answerId: answerId,type: type, id:id } })
    },

    moment,
    onChange(date, dateString) {
      console.log(date, dateString);
      this.proJect.startTime = dateString[0];
      this.proJect.endTime = dateString[1];
    },
    onChangeto(date, dateString) {
      console.log(date, dateString);
      this.proJect.issueStartTime = dateString[0];
      this.proJect.issueEndTime = dateString[1];
    },
    // 搜索
    search() {
      this.proJect.pageNum = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.proJect.pageNum = e.current;
      this.proJect.pageSize = e.pageSize;
      this.getManageList();
    },
    // 去阅卷
    gohomeWorkDetail(e, r) {
      this.$router.push(
        "/admin/Subjective/homeWorkDetail?id=" + e + "&type=" + r
      );
    },

    // 查询列表
    getManageList() {
      this.$ajax({
        method: "GET",
        url: "/hxclass-management/homeworkAnswer/select/answer-history",
        params: this.proJect,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/box",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 99999,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getCourseList();
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-top: 20px;
  line-height: 19px;
}
.dlex {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dlexitem {
    width: 26%;
    display: flex;
    align-items: center;
    > span {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: block;
      flex-shrink: 0;
      width: 80px;
    }
    .nput {
      width: 240px;
      // height: 44px;
      border-radius: 3px 3px 3px 3px;
    }
  }
}
.dlex:nth-child(3) {
  margin-bottom: 24px;
  margin-top: 16px;
}
</style>